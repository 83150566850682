import { applyBranding } from 'Lib/white-labeler';

import * as actions from './actions';
import * as storage from './storage';

import agent from '~/agent';
import store from '~/store';

export const initialize = () => {
    agent.request
        .get('init')
        .then(response => {
            const defaultPortal = storage.getPortal();
            const defaultLease = storage.getLease();

            const { portals, portalAccounts, user, account, residentSettings, brandSettings, company } = response.data;

            let portal = null;
            if (defaultPortal) {
                portal = portals.find(item => item.tenant.contactID == defaultPortal);
            }

            if (!portal) {
                portal = portals.length == 1 ? portals[0] : null;
                if (portal) {
                    storage.setPortal(portal.contactID);
                }
            }

            let lease = null;
            if (portal) {
                if (defaultLease) {
                    lease = portal.leases.find(item => item.lease.leaseID == defaultLease);
                }

                if (!lease) {
                    lease = portal && portal.leases.length === 1 ? portal.leases[0] : null;

                    if (lease) {
                        storage.setLease(lease.leaseID);
                    }
                }
            }

            applyBranding(`https://cdn${CONFIG.domain}/${account.directory}/branding/${brandSettings.webIconFileName}`);

            store.dispatch(
                actions.init({
                    isInitialized: true,
                    hasError: false,
                    errorMessage: null,
                    user,
                    portals,
                    portalAccounts,
                    portal,
                    account,
                    lease,
                    changePortal: portal ? false : true,
                    changeLease: lease ? false : true,
                    isImpersonated: response.data.impersonation ? true : false,
                    impersonation: response.data.impersonation,
                    residentSettings,
                    brandSettings,
                    company
                })
            );
        })
        .then(() => {
            getLease();
        })
        .catch(error => {
            if (error.response.status == 401) {
                store.dispatch(
                    actions.init({
                        isInitialized: false,
                        hasError: false,
                        doLogin: true,
                        errorMessage: 'Could not initialize the application.'
                    })
                );
            } else {
                store.dispatch(
                    actions.init({
                        hasAuthenticationError: true,
                        isInitialized: false,
                        hasError: true,
                        errorMessage: 'Could not initialize the application.'
                    })
                );
            }
        });
};

export const getLeases = () => {
    agent.request.get('leases', { includes: 'unit' }).then(response => {
        const leases = response.data;
        let lease = false;
        let unit = false;

        if (leases.length == 1) {
            lease = leases[0].lease;
            unit = leases[0].unit;
        }

        store.dispatch(actions.getLeases(leases, lease, unit));
    });
};

export const getLease = () => {
    agent.request
        .get('lease', { includes: 'unit,rentersInsuranceFile,rentersInsuranceRequest,tenantLiabilityInsurance,leaseTenant' })
        .then(response => {
            store.dispatch(actions.setLease(response.data));
        })
        .catch(error => {
            if (error?.message?.includes('403') || error?.response?.status === 403) {
                portalDisabled();
            }
        });
};

export const changePortal = () => {
    store.dispatch(actions.changePortal());
};

export const cancelChangePortal = () => {
    store.dispatch(actions.cancelChangePortal());
};

export const setPortal = portal => {
    store.dispatch(actions.setPortal(portal));
};

export const changeLease = () => {
    store.dispatch(actions.changeLease());
};

export const cancelChangeLease = () => {
    store.dispatch(actions.cancelChangeLease());
};

export const setLease = lease => {
    store.dispatch(actions.setLease(lease));
    getLease();
};

export const portalDisabled = () => {
    store.dispatch(actions.portalDisabled());
};

/**
 * API
 */
export const callApi = (promise, exclude = false) => {
    if (!exclude) {
        store.dispatch(actions.startApiCall());
    }

    if (promise) {
        promise
            .catch?.(error => {
                // TODO: check the status code is 401
                if (error.response?.status == 401) {
                    store.dispatch(actions.logout());
                }
            })
            .finally?.(() => {
                if (!exclude) {
                    store.dispatch(actions.endApiCall());
                }
            });
    }
};
