import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/payment-methods',
        component: Loader(() => import('~/containers/payment-method/List'))
    },
    {
        path: '/make-payment/recurring-payments',
        component: Loader(() => import('~/containers/recurring-payment/List'))
    },
    {
        path: '/make-payment/recurring-payments/new',
        component: Loader(() => import('~/containers/recurring-payment/New'))
    },
    {
        path: '/make-payment',
        component: Loader(() => import('~/containers/make-payment/View'))
    }
];

export default routes;
