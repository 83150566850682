/**
 * Initialize
 */
export const INIT = 'app/INIT';

/**
 * Logout
 */
export const LOGOUT = 'app/LOGOUT';

/**
 * Portal Actions
 */
export const CHANGE_PORTAL = 'app/CHANGE_PORTAL';
export const CANCEL_CHANGE_PORTAL = 'app/CANCEL_CHANGE_PORTAL';
export const SET_PORTAL = 'app/SET_PORTAL';
export const PORTAL_DISABLED = 'app/PORTAL_DISABLED';

/**
 * Lease Actions
 */
export const GET_LEASES = 'app/GET_LEASES';
export const CHANGE_LEASE = 'app/CHANGE_LEASE';
export const CANCEL_CHANGE_LEASE = 'app/CANCEL_CHANGE_LEASE';
export const SET_LEASE = 'app/SET_LEASE';

/**
 * API Actions
 */
export const START_API_CALL = 'app/START_API_CALL';
export const END_API_CALL = 'app/END_API_CALL';

/**
 * Notify
 */
export const NOTIFY = 'NOTIFY';
