import {
	MENU_ITEM_SELECTED
} from 'Lib/constants/app/actionTypes'

export default (state = {}, action) => {
	switch(action.type){
		case MENU_ITEM_SELECTED:
			let selectedItem = action.name

			if(selectedItem == state.selectedItem){
				selectedItem = null
			}

			return {
				...state,
				selectedItem
			}
			break;
		default:
			return state
	}
}