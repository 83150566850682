import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { STATS_INTERVAL_TIMER } from 'Lib/components/chat/Chat';
import SentryErrorBoundary from 'Lib/components/errors/SentryErrorBoundary';
import Navigation from 'Lib/components/layout/Navigation';
import NotFound from 'Lib/components/layout/NotFound';
import PortalTypes from 'Lib/constants/app/portalTypes';
import ChatObjectTypes from 'Lib/constants/communications/chatObjectTypes';
import QueryClientProvider from 'Lib/QueryClient';
import navigationPermissions from 'Lib/utilities/navigationPermissions';

import LeaseSelected from './LeaseSelected';
import PortalSelected from './PortalSelected';

import 'react-toastify/dist/ReactToastify.css';
import '~/scss/app.scss';

import { getMessageStats } from '~/api/chat';
import NAVIGATION_ITEMS from '~/constants/layout.js/NavigationItems';
import Authenticated from '~/containers/Authenticated';
import AppLayout from '~/containers/layouts/App';
import routes from '~/routes';
import * as app from '~/services/app';
import * as session from '~/services/session';
import { showMenu } from '~/services/ui/actions';

const mapStateToProps = state => ({
    app: state.app,
    portalAccounts: state.app.portalAccounts,
    ui: state.ui
});

const mapDispatchToProps = {
    showMenu
};

class App extends Component {
    state = {
        items: [],
        intervalID: null
    };

    componentDidMount() {
        document.body.addEventListener('click', this.onClick);

        this.setState({
            screenWidth: window.screen.width
        });

        if (window.screen.width < 475) {
            this.setState({ showHamburger: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.app) != JSON.stringify(this.props.app)) {
            const { residentSettings } = this.props.app;
            const permissions = { ...residentSettings };

            this.setState({
                items: navigationPermissions(permissions, NAVIGATION_ITEMS, {
                    isServiceRequestDisabled: () => {
                        if (residentSettings?.isServiceRequestDisabled == '1') {
                            const path = residentSettings.serviceRequestUrl;

                            if (path) {
                                return {
                                    path,
                                    external: true,
                                    label: 'Maintenance',
                                    icon: 'home_repair_service'
                                };
                            }
                        } else {
                            return {
                                path: '/service-requests',
                                label: 'Maintenance',
                                icon: 'home_repair_service'
                            };
                        }
                    }
                })
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalID);
    }

    onChangeLease = lease => {
        app.setLease(lease);
    };

    onChangePortal = portal => {
        app.setPortal(portal);

        if (portal.leases.length > 1) {
            app.changeLease();
        } else {
            app.setLease(portal.leases[0]);
        }
    };

    render() {
        const { items } = this.state;
        const { app, ui, portalAccounts } = this.props;

        return (
            <>
                <Router basename="/portals/resident">
                    <Container
                        disableGutters
                        maxWidth={false}
                        sx={theme => ({
                            bgcolor: 'common.background',
                            [theme.breakpoints.up('md')]: {
                                height: '100vh',
                                overflow: 'hidden'
                            }
                        })}
                    >
                        <Authenticated>
                            <QueryClientProvider>
                                <PortalSelected>
                                    <LeaseSelected>
                                        <Navigation
                                            key={items}
                                            app={app}
                                            items={items}
                                            session={session}
                                            open={ui.menuVisible}
                                            portalAccounts={portalAccounts}
                                            portalTypeID={PortalTypes.Tenant}
                                            toggleDrawer={() => this.props.showMenu(!ui.menuVisible)}
                                            onChangePortal={this.onChangePortal}
                                            onChangeLease={this.onChangeLease}
                                        />
                                        <Switch>
                                            {routes.map((route, index) => (
                                                <Route
                                                    key={index}
                                                    exact={true}
                                                    path={route.path}
                                                    render={props => {
                                                        let Layout = AppLayout;

                                                        if (route.layout) {
                                                            Layout = route.layout;
                                                        }

                                                        return (
                                                            <Layout>
                                                                <SentryErrorBoundary>
                                                                    <route.component route={props} routes={route.routes} />
                                                                </SentryErrorBoundary>
                                                            </Layout>
                                                        );
                                                    }}
                                                />
                                            ))}
                                            <Route route="*" component={NotFound} />
                                        </Switch>
                                    </LeaseSelected>
                                </PortalSelected>
                                <ReactQueryDevtools initialIsOpen={false} />
                            </QueryClientProvider>
                        </Authenticated>
                    </Container>
                </Router>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
