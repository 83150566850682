import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/service-requests',
        component: Loader(() => import('~/containers/maintenance/List'))
    },
    {
        path: '/service-requests/add',
        component: Loader(() => import('~/containers/maintenance/Add'))
    },
    {
        path: '/service-requests/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/View'))
    }
];

export default routes;
