import * as actionTypes from './actionTypes';
import * as storage from './storage';

/**
 * Init
 */
export const init = app => ({
    type: actionTypes.INIT,
    app
});

/**
 * Logout
 */
export const logout = () => ({
    type: actionTypes.LOGOUT
});

/**
 * Portal Actions
 * */
export const changePortal = () => ({
    type: actionTypes.CHANGE_PORTAL
});

export const cancelChangePortal = () => ({
    type: actionTypes.CANCEL_CHANGE_PORTAL
});

export const setPortal = portal => {
    storage.setPortal(portal.tenant.contactID);

    return {
        type: actionTypes.SET_PORTAL,
        portal
    };
};

export const portalDisabled = () => ({
    type: actionTypes.PORTAL_DISABLED
});

/**
 * Lease Actions
 */
export const getLeases = (leases, lease, unit) => {
    return {
        type: actionTypes.GET_LEASES,
        leases,
        lease,
        unit
    };
};

export const changeLease = () => ({
    type: actionTypes.CHANGE_LEASE
});

export const cancelChangeLease = () => ({
    type: actionTypes.CANCEL_CHANGE_LEASE
});

export const setLease = lease => {
    storage.setLease(lease?.lease?.leaseID);

    return {
        type: actionTypes.SET_LEASE,
        lease
    };
};

/**
 * API Actions
 */
export const startApiCall = () => ({
    type: actionTypes.START_API_CALL
});

export const endApiCall = () => ({
    type: actionTypes.END_API_CALL
});

/**
 * Notify
 */
export const notify = data => ({
    type: actionTypes.NOTIFY,
    notify: data
});
