import update from 'immutability-helper';

import PortalTypes from 'Lib/constants/app/portalTypes';

import * as actionTypes from './actionTypes';

export const initialState = {
    isInitialized: false,
    portalTypeID: PortalTypes.Tenant
};

export const reducer = (state = initialState, action) => {
    let lease;

    switch (action.type) {
        case actionTypes.INIT:
            return {
                ...state,
                ...action.app
            };
        case actionTypes.CHANGE_PORTAL:
            return update(state, {
                changePortal: { $set: true }
            });
        case actionTypes.CANCEL_CHANGE_PORTAL:
            return update(state, {
                changePortal: { $set: false }
            });
        case actionTypes.SET_PORTAL:
            lease = action.portal.leases.length == 1 ? action.portal.leases[0] : null;

            return update(state, {
                portal: { $set: action.portal },
                changePortal: { $set: false },
                lease: { $set: lease },
                changeLease: { $set: true }
            });
        case actionTypes.PORTAL_DISABLED:
            if (!state.lease) {
                return state;
            }

            return update(state, {
                lease: { lease: { isPortalDisabled: { $set: 1 } } },
                changeLease: { $set: true }
            });
        case actionTypes.GET_LEASES:
            return update(state, {
                leases: { $set: action.leases },
                lease: { $set: action.lease },
                unit: { $set: action.unit }
            });
        case actionTypes.CHANGE_LEASE:
            return update(state, {
                changeLease: { $set: true }
            });
        case actionTypes.CANCEL_CHANGE_LEASE:
            return update(state, {
                portal: { $set: {} },
                changePortal: { $set: true }
            });
        case actionTypes.SET_LEASE:
            return update(state, {
                lease: {
                    $set: action.lease
                },
                changeLease: { $set: false }
            });
        default:
            return state;
    }
};

export default reducer;
