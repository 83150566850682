import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from 'reactstrap/lib/Button';

import * as app from '~/services/app';
import * as session from '~/services/session';

const mapStateToProps = state => ({
    app: state.app
});

class PortalSelect extends Component {
    onCancel = () => {
        app.cancelChangePortal();
    };

    onSelect = portal => {
        app.setPortal(portal);
    };

    onLogout = () => {
        session.logout();
    };

    renderSelector() {
        const { portals } = this.props.app;
        return (
            <div style={{ backgroundColor: '#DEE7E7', height: '100vh' }}>
                <div id="selector" className="portal-select-container">
                    <div className="portal-select">
                        <h2>Select a Portal:</h2>
                        <br />
                        <ul>
                            {portals.map((item, index) => {
                                const splitName = item.tenant.name.split(' ');
                                const initials = splitName[0][0] + (splitName[1] ? splitName[1][0] : '');
                                const color = initials[0].toLowerCase();
                                return (
                                    <div key={index} className="portal-name">
                                        <li onClick={() => this.onSelect(item)}>
                                            <div className="user-icon">
                                                <p className={`portal-icon-${color}`}>{initials}</p>
                                            </div>
                                            <div className="user-name">
                                                <p>{item.tenant.name}</p>
                                            </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <div>
                            <Button outline onClick={this.onLogout} size="sm">
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { portals, portal, changePortal = false } = this.props.app;

        if (!portals) {
            return <div>Loading Portals...</div>;
        }

        if (changePortal) {
            return this.renderSelector();
        }

        if (!portal) {
            if (portals.length == 0) {
                return <div>You do not have access to any portals</div>;
            }

            return this.renderSelector();
        }

        return <Fragment>{this.props.children}</Fragment>;
    }
}

export default connect(mapStateToProps, null)(PortalSelect);
