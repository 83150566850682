import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Alert, Badge, Button } from 'reactstrap';

import AnimatedLogo from 'Lib/assets/AnimatedLogo';
import PrimaryLeaseStatuses from 'Lib/constants/leases/primaryLeaseStatuses';
import isEmpty from 'Lib/utilities/isEmpty';
import { limitLength } from 'Lib/utilities/limitLength';
import { renderPropertyAddressAndName } from 'Lib/utilities/property';

import { badgeColor, sortLeasesByPrimaryLeaseStatusIDAndAddress } from '~/lib/selectLeasesUtils';
import * as app from '~/services/app';

const mapStateToProps = state => ({
    app: state.app
});

class LeaseSelected extends Component {
    state = {
        tenant: null
    };

    componentDidMount() {
        this.props.app.portals.map(item => {
            if (item.tenant.name == this.props.app.portal.name) {
                this.setState({ tenant: item });
            }
        });
    }

    onCancel = () => {
        app.cancelChangeLease();
    };

    onSelect = item => {
        app.setLease(item);
    };

    renderSelector = () => {
        const { portal } = this.props.app;
        const { leases } = portal;
        const sortedLeases = sortLeasesByPrimaryLeaseStatusIDAndAddress(leases);

        return (
            <div style={{ backgroundColor: '#DEE7E7', height: '100vh' }}>
                <div id="selector" className="portal-select-container">
                    <div className="portal-select">
                        {!isEmpty(portal.leases) ? (
                            <>
                                <h2>Select a Lease:</h2>
                                <br />
                                <ul>
                                    {portal
                                        ? sortedLeases.map(({ lease, unit }, index) => {
                                              const removeNumbers = renderPropertyAddressAndName(unit).replace(/[0-9]/g, '').trimStart();
                                              const initial = removeNumbers[0];
                                              const color = initial.toLowerCase();

                                              return (
                                                  <div key={index} className="portal-name">
                                                      <li onClick={() => this.onSelect({ lease, unit })}>
                                                          <div className="user-icon">
                                                              <p className={`portal-icon-${color}`}>{initial}</p>
                                                          </div>
                                                          <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                              <div className="user-name">
                                                                  <p>{limitLength(unit.address, 20)}</p>
                                                                  {unit.address2 ? <p> {limitLength(unit.address2, 20)}</p> : null}
                                                              </div>
                                                              <div className="lease-status-badge">
                                                                  <Badge style={{ paddingTop: '5px' }} color={badgeColor(lease.primaryLeaseStatusID)}>
                                                                      {PrimaryLeaseStatuses.getName(lease.primaryLeaseStatusID)}
                                                                  </Badge>
                                                              </div>
                                                          </div>
                                                      </li>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </ul>
                            </>
                        ) : (
                            <div>
                                This tenant is currently not associated to a lease.
                                <br />
                                <div style={{ paddingTop: '21px', textAlign: 'center' }}>
                                    <Button onClick={this.onCancel}>Back</Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { portals, lease, changeLease = false } = this.props.app;

        if (!portals) {
            return <AnimatedLogo />;
        }

        if (changeLease) {
            if (lease && lease.lease.isPortalDisabled == 1) {
                return (
                    <>
                        <div>
                            <Alert color="danger" style={{ marginBottom: '0' }}>
                                Portal has been disabled. Please contact your property manager for information.
                            </Alert>
                        </div>
                        {this.renderSelector()}
                    </>
                );
            } else {
                return this.renderSelector();
            }
        }

        return this.props.children;
    }
}

export default connect(mapStateToProps, null)(LeaseSelected);
