const PORTAL_KEY = "tenant/portal";
const LEASE_KEY = "tenant/lease";

export const getPortal = () => {
	return window.localStorage.getItem(PORTAL_KEY);
};

export const setPortal = contactID => {
	window.localStorage.setItem(PORTAL_KEY, contactID);
};

export const getLease = () => {
	return window.localStorage.getItem(LEASE_KEY);
};

export const setLease = leaseID => {
	return window.localStorage.setItem(LEASE_KEY, leaseID);
};