// routes.js
import { Redirect } from 'react-router-dom';

import Loader from 'Lib/utilities/Loader';

import chat from './chat';
import filesRoutes from './files';
import insurance from './insurance';
import ledger from './ledger';
import maintenanceRoutes from './maintenance';
import paymentRoutes from './payment';
import receiptRoutes from './receipts';

const routes = [
    {
        path: '/',
        component: () => <Redirect to="/summary" /> // Redirect to /summary
    },
    {
        path: '/summary',
        component: Loader(() => import('~/containers/dashboard/Index'))
    },
    ...filesRoutes,
    ...maintenanceRoutes,
    ...paymentRoutes,
    ...receiptRoutes,
    ...ledger,
    ...insurance,
    ...chat
];

export default routes;
