/*
 * Icon library here: https://fonts.google.com/icons
 * All icons with use the same className of "material-symbols-rounded" (rounded version)
 *
 * `permissionsFlag` is only added if the item itself is only visible based off of Redux permissions.
 * e.g. Ledger item will run a permission check in `App.js` to check the value `isLiveLedgerEnabled`
 * and only render if the value is `1` or `true`; see `navigationPermissions.js` for more info there.
 */
const NAVIGATION_ITEMS = [
    {
        label: 'Summary',
        path: '/summary',
        icon: 'summarize'
    },
    {
        label: 'Make Payment',
        path: '/make-payment',
        icon: 'monetization_on'
    },
    {
        label: 'Activity',
        path: '/ledger',
        icon: 'show_chart'
    },
    {
        label: 'Wallet',
        path: '/payment-methods',
        icon: 'account_balance_wallet'
    },
    {
        label: 'Files',
        path: '/files',
        icon: 'home_storage'
    },
    {
        label: 'Insurance',
        path: '/insurance',
        icon: 'shield'
    },
    {
        label: 'Maintenance',
        path: '/service-requests',
        icon: 'home_repair_service',
        permissionFlag: 'isServiceRequestDisabled'
    },
    {
        label: 'Chat',
        path: '/chat',
        icon: 'chat',
        badgeID: 'unreadMessageCount'
    }
];

export default NAVIGATION_ITEMS;
