import PrimaryLeaseStatuses from 'Lib/constants/leases/primaryLeaseStatuses';

export const badgeColor = (status) => {
    switch(parseInt(status)) {
        case PrimaryLeaseStatuses.Active:
            return 'success';
        case PrimaryLeaseStatuses.Closed:
            return 'danger';
        case PrimaryLeaseStatuses.Pending:
            return 'warning';
    }
}

export const sortLeasesByPrimaryLeaseStatusIDAndAddress = (leases) => {
    leases.sort((a, b) => {
        if(a.unit.address.replace(/[0-9]/g, '') < b.unit.address.replace(/[0-9]/g, '')) { return -1; }
        if(a.unit.address.replace(/[0-9]/g, '') > b.unit.address.replace(/[0-9]/g, '')) { return 1; }
        return 0;
    })
    leases.sort((a, b) => parseInt(a.lease.primaryLeaseStatusID) - parseInt(b.lease.primaryLeaseStatusID))

    return leases
}