import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import menu from './menu';
import app from '~/services/app/reducer';
import dataCache from '~/services/cache/reducer';
import session from '~/services/session/reducer';
import ui from '~/services/ui/reducer';

export default history =>
    combineReducers({
        app,
        menu,
        dataCache,
        router: connectRouter(history),
        session,
        ui
    });
