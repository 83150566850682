import { createAgent } from 'Lib/portalAgent';

import * as actions from './services/session';

import store from '~/store';

const agent = createAgent(actions, store);

export const request = agent.request;
export default agent;
